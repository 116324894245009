import "../styles/login.scss"
import React, { useState, useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
// import Header from "../components/Header"
// import Header from "../components/BlueHeader"
import Header from "../components/TealHeader"
import ReverseAuthGate from "../components/Auth/ReverseAuthGate"
// import Footer from "../components/Footer"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import { navigate, graphql } from "gatsby"
// import { useFirebaseArsenal } from "../../firebase/firebaseCentral"
// import { useAuth } from "../lib/firebase/firebaseCentral"
// import Img from "gatsby-image"
import toast from "react-hot-toast"
// import se from "react-netlify-identity";
import GoTrue from "gotrue-js"
// import favicon from "../images/favicon.ico"
import favicon from "../images/favicon.png"

export default function PasswordRecovery() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [disable, setDisable] = useState(true)
  const [identity, setIdentity] = useState(null)
  const emailRef = useRef(null)

  // console.log("se", se);
  // console.log("isLoggedIn", isLoggedIn);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm()
  const { ref, ...rest } = register("email", {
    required: true,
    minLength: 3,
    maxLength: 20,
    pattern: {
      value: /\S+@\S+.\S+/,
      message: "Entered value does not match email format",
    },
  })

  useEffect(() => {
    const auth = new GoTrue({
      APIUrl: "https://www.berserkers.dev/.netlify/identity",
      audience: "",
      // setCookie(optional): set to be false by default. If you wish to implement the remember me functionality, set the value to be true.
      setCookie: false,
    })

    setIdentity(auth)
  }, [])

  // useEffect(() => {
  //   document.documentElement.style.setProperty(
  //     "--scrollbar-width",
  //     window.innerWidth - document.documentElement.clientWidth + "px"
  //   )
  // }, [])

  useEffect(() => {
    if (emailRef.current) emailRef.current.focus()
  }, [emailRef])

  // * -------------------------------------------- *
  // console.log("useAuth", useAuth())
  // const { signinWithEmail, sendPasswordResetEmail, signout } = useAuth()
  // console.log("signinWithEmail", signinWithEmail)
  // console.log("signout", signout)

  useEffect(() => {
    if (identity?.currentUser) {
      // console.log("identity.currentUser()", identity.currentUser());
    }
  }, [identity])

  const onSubmit = async data => {
    if (identity?.requestPasswordRecovery) {
      identity
        .requestPasswordRecovery(data.email)
        .then(response => {
          reset({ email: "" })
          toast.success(
            "Your reset password email has been sent! Be sure to check spam too!"
          )
        })
        .catch(error => {
          toast.error("Something went wrong. Try again or please contact us.")
        })
    }
  }

  // console.log("errors", errors)
  // console.log(watch("email")) // watch input value by passing the name of it
  // console.log(watch("password")) // watch input value by passing the name of it

  return (
    <>
      {/* <ReverseAuthGate> */}
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Password Recovery</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      <div className="login-wrapper">
        {/* <div className="login-inner-wrapper"> */}

        <>
          <div className="login-img-left">
            {/* <Img fluid={data.two.childImageSharp.fluid} /> */}
            <StaticImage src="../images/abstract_5.png" />
          </div>
          <div className="login-img-right">
            <StaticImage src="../images/abstract_6.png" />
            {/* <Img fluid={data.one.childImageSharp.fluid} /> */}
          </div>
        </>

        <form className="login" onSubmit={handleSubmit(onSubmit)}>
          <div className="login__header">
            <div>Recover your password</div>
          </div>
          <div className="login__container">
            <label className="login__label" htmlFor="email">
              Email
            </label>
            <input
              className="login__input"
              id="email"
              type="email"
              // placeholder="Enter your email"
              {...register("email", {
                required: { value: true, message: "email is required" },
                minLength: { value: 5, message: "Min chars is 5" },
                maxLength: {
                  value: 300,
                  message: "Max length is 300 characters",
                },
                pattern: {
                  value: /\S+@\S+.\S+/,
                  message: "Entered value does not match email format",
                },
              })}
            />
            {errors.email && <span>{errors.email.message}</span>}

            <button
              type="submit"
              className="login__confirm"
              // onClick={() => setStep("two")}
            >
              Send recovery email
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
